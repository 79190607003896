import React, { useContext } from 'react';
import { GettextContext } from '../../context';

function Modal({onModalClose, text}) {
    const gettext = useContext(GettextContext);

    return (
        <div className={'cancel-modal'}>
            <div className={'cancel-modal_header'}>
                <div className={'cancel-modal_close'}
                    onClick={() => onModalClose(false)}
                >
                    <span>×</span>
                </div>
            </div>
            <div className={'cancel-modal_body'}>
                <div className={'cancel-modal_wrapper'}>
                    <div className={'cancel-modal_icon mod-img-trash'}></div>
                    <div className={'cancel-modal_text'}>
                        <div className={'cancel-modal_text-item'}>
                            {gettext.getString('Вы генерируете новую ссылку. Ранее созданная ссылка станет не валидной.')}
                        </div>
                        <div className={'cancel-modal_text-item'}>
                            {gettext.getString('Обновить ссылку?')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'cancel-modal_footer'}>
                <button className={'btn btn-default'}
                    onClick={() => onModalClose(false)}
                >
                    {gettext.getString('Отменить')}
                </button>
                <button className={'btn btn-danger cancel-modal_button'}
                    onClick={() => onModalClose(true)}
                >
                    {gettext.getString('Обновить')}
                </button>
            </div>
        </div>
    );
}

export default Modal;
