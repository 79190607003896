import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GettextContext } from 'rootReact/context';
import FetchApi from '../../../REST';
import LanguageSelector from './LanguageSelector.jsx';
import ModalHeader from '../../ModalHeader';
import ModalFooter from '../../ModalFooter';
import loader from '../../../../../images/loading.svg';
import FaIcon from 'rootReact/components/FaIcon';
import { useInput } from '../../../hook/useValidModal.jsx';
import Locale from './Locale.jsx';
import { addCourse, updateCourse } from '../../../../toolkit/actions';
import DropdownSelect from '../../DropdownSelect';
import { checkTranslations, setLocalesFormData } from '../../../helpers';
import { contentFetch } from '../../../../helpers/contentFetch';

const schema = {
    files: {
        dropzone: 'courseFile',
        imageFile: 'previewFile',
    },
    data: [
        'languageId',
        'isDefault',
        'passScore',
        'name',
        'courseFilename',
        'previewBase64',
        'imgHeight',
        'imgWidth',
        'sizeSuccess',
    ],
};

function CoursesModal({ id, onModalClose }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [formError, setFormError] = useState('');
    const [emptyError, setEmptyError] = useState(false);
    const [localeEmptyError, setLocaleEmptyError] = useState(false);
    const [save, setSave] = useState(false);
    const [onClickSave, setOnClickSave] = useState(false);
    const [loading, setLoading] = useState(true);
    const languages = useSelector((state) => state.languages.languages);
    const [activeLanguage, setActiveLanguage] = useState(languages[0].id);
    const [locales, setLocales] = useState([]);
    const certs = useSelector((state) => state.certificate.certs);
    const [certificate, setCertificate] = useState(certs);
    const [checkboxAppointment, setCheckboxAppointment] = useState(false);
    const duration = useInput(365, { isEmpty: true });
    const currentLocales = locales?.some(
        (item) => item?.languageId === activeLanguage && item.courseFilename.length !== 0
    );
    const [defaultLangId, setDefaultLangId] = useState(false);
    const localeIndex = locales?.findIndex((item) => item?.languageId === activeLanguage);
    const [errorType, setErrorType] = useState('');
    const [errorName, setErrorName] = useState('');

    useEffect(() => {
        async function fetchApi() {
            setLoading(true);
            let response;
            if (id) {
                response = await FetchApi('/courses').get(id);
            }
            if ((response && response.isError) || !response) {
                throw response;
            }
            const langId = response.locales.find((local) => local.isDefault === 1).languageId;
            setActiveLanguage(langId);
            setInitLocales(response.locales);
            setCertificate(certificate?.find((item) => item.id === response.certificate_id));
            duration.onChange(response.certificate_duration);
            setCheckboxAppointment(response.allow_self_appointment);
            setLoading(false);
        }
        try {
            if (id) {
                fetchApi();
            } else {
                setInitLocales([]);
                setLoading(false);
            }
        } catch (e) {
            setFormError(e.err.message);
        }
    }, [id]);

    const handleActiveLanguage = (languageId) => {
        let arr = setDefaultLanguage(languageId, locales);
        setLocales(arr);
    };

    const setDefaultLanguage = (languageId, locales = []) => {
        if (!locales.length) return;
        setDefaultLangId(languageId);
        let arr = locales.map((item) => {
            if (item.languageId === languageId) {
                item.isDefault = 1;
            } else {
                item.isDefault = 0;
            }
            return item;
        });
        return arr;
    };

    useEffect(() => {
        const certDefault = certs.find((item) => item.isDefault);
        if (certDefault) {
            setCertificate(certDefault);
        }
    }, [certs]);

    const setInitLocales = (locales) => {
        let initLocales = [];
        let isDefaultLocale = false;
        languages.forEach((langItem, i) => {
            let locale = locales?.find((item) => item.languageId === langItem.id);
            if (locale?.isDefault) {
                isDefaultLocale = locale.languageId;
            }
            if (locale) {
                initLocales.push(locale);
            } else {
                initLocales.push({
                    name: '',
                    passScore: '',
                    isDefault: 0,
                    courseFilename: '',
                    previewBase64: '',
                    languageId: langItem.id,
                    dropzone: '',
                    imageFile: '',
                    previewFilename: '',
                    imgHeight: '',
                    imgWidth: '',
                    sizeSuccess: '',
                });
            }
        });
        if (!Number.isInteger(isDefaultLocale)) {
            initLocales = setDefaultLanguage(languages[0].id, initLocales);
            setDefaultLangId(languages[0].id);
        } else {
            setDefaultLangId(isDefaultLocale);
        }
        setLocales(initLocales);
    };

    const setActiveLocale = (locale) => {
        setLocales(
            locales.map((item) => {
                if (item.languageId === activeLanguage) {
                    return locale;
                } else {
                    return item;
                }
            })
        );
    };

    const updateLocaleEmptyError = (value) => {
        setLocaleEmptyError(value);
    };

    const getActiveLocale = () => {
        let locale = locales.find((item) => item.languageId === activeLanguage);
        if (!locale) return;
        return (
            <Locale
                setValue={setActiveLocale}
                key={activeLanguage}
                initValue={locale}
                activeLanguage={activeLanguage}
                updateEmptyError={updateLocaleEmptyError}
                save={onClickSave}
                setActiveLanguage={handleActiveLanguage}
                defaultLangId={defaultLangId}
                currentLocales={currentLocales}
                localeIndex={localeIndex}
                id={id}
                errorName={errorName}
                errorType={errorType}
                setErrorName={setErrorName}
                setErrorType={setErrorType}
            />
        );
    };

    const saveCourse = async () => {
        setFormError('');
        setSave(true);
        let localesFinal = checkTranslations(locales);
        let formData = await setLocalesFormData(localesFinal, schema);
        if (certs.length !== 0) {
            formData.append('allow_self_appointment', Number(checkboxAppointment));
        }
        if (certs.length !== 0 && certificate) {
            formData.append('certificate_id', certificate.id);
        }
        if (certs.length !== 0 && duration.value) {
            formData.append('certificate_duration', duration.value);
        }
        try {
            let course = await contentFetch({ body: formData, content: 'courses' }, id);
            if (course.errors) {
                throw course.errors;
            }
            if (id) {
                dispatch(updateCourse(course));
            } else {
                dispatch(addCourse(course));
            }
            setSave(false);
            onModalClose();
        } catch (err) {
            setSave(false);
            setErrorName(err.name && err.name[0]);
            setErrorType(err.courseFile && err.courseFile[0]);
        }
    };

    return (
        <>
            <ModalHeader
                style={{ borderBottom: 'none' }}
                title={gettext.getString(`${id ? 'Редактирование курса' : 'Новый курс'}`)}
                closeModal={() => onModalClose()}
            />
            {loading ? (
                <img className='modal-loader' src={loader} />
            ) : (
                <div className='modal-course'>
                    <LanguageSelector languages={languages} activeId={activeLanguage} onSelect={setActiveLanguage} />

                    {getActiveLocale()}
                    <>
                        {certs.length !== 0 && currentLocales && (
                            <>
                                <div className='modal-course_block'>
                                    <label
                                        className={
                                            localeEmptyError ? 'modal-course_label text-error' : 'modal-course_label'
                                        }
                                        htmlFor='cert'
                                    >
                                        {gettext.getString('Сертификат')}
                                    </label>
                                    <div className='modal-course_select'>
                                        <DropdownSelect
                                            idClass={'attachment_chose'}
                                            inputAttr={{ value: certificate?.name }}
                                            Style={['react-input_field']}
                                            optionsName={'name'}
                                            testId={'cets-item'}
                                            testIdList={'certs-list'}
                                            testIdSearch={'certs-search-field'}
                                            handleClick={(item) => {
                                                setCertificate(item);
                                            }}
                                            //placeholder={gettext.getString("Страница")}
                                            list={certs}
                                        />
                                    </div>
                                </div>
                                <div className='modal-course_block'>
                                    <label
                                        className={
                                            (localeEmptyError && !duration.value.length) ||
                                                (duration.isDirty && duration.isEmpty)
                                                ? 'modal-course_label text-error'
                                                : 'modal-course_label'
                                        }
                                        htmlFor='duration'
                                    >
                                        {gettext.getString('Срок действия сертификата')}
                                    </label>
                                    <input
                                        onChange={(e) => duration.onChange(e.target.value)}
                                        onBlur={(e) => duration.onBlur(e)}
                                        value={duration.value}
                                        className={'modal-course_input-cert'}
                                        type='number'
                                        autoComplete={'off'}
                                        id='duration'
                                    />
                                    <span>{gettext.getString('дней')}</span>
                                </div>
                                <div className='modal-course_block-bottom'>
                                    <input
                                        checked={checkboxAppointment}
                                        onClick={() => setCheckboxAppointment((prev) => !prev)}
                                        className='modal-course_checkbox-appointment'
                                        type='checkbox'
                                        id='checkbox-appointment'
                                    />
                                    <label className='modal-course_label-appointment' htmlFor='checkbox-appointment'>
                                        {gettext.getString('Можно изучать самостоятельно')}
                                    </label>
                                </div>
                            </>
                        )}
                    </>
                </div>
            )}
            <div className='modal-course_process'>
                <ModalFooter
                    titleBtnOk={gettext.getString('Сохранить')}
                    titleBtnClose={gettext.getString('Отменить')}
                    closeModal={() => onModalClose()}
                    disabled={!currentLocales || save ? true : false}
                    onEnter={saveCourse}
                />
                {formError.length !== 0 ? <FaIcon className='react-icon-error' type={'error'} text={formError} /> : ''}
                {save ? <FaIcon className='react-icon-error' type={'pending'} text={gettext.getString('Сохранение...')} /> : ''}
            </div>
        </>
    );
}

export default CoursesModal;
