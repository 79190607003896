import React, {useContext} from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useSelector, useDispatch} from 'react-redux';
import { LdapProfileModal } from 'root/assets/webpack/src/js/components/ldapProfileModal';
import { deleteAccount } from 'root/assets/webpack/src/js/toolkit/accountUsers';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { ldapSchema } from './SettingsLdapSchema.jsx';

function SettingsLdap({licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const profiles = useSelector(state => state.settings.settings.ldapProfiles);
    const dispatch = useDispatch();

    const getHeaderLink = () => {
        return (
            <div>
                <a onClick={() => licenseIsValid && openModal()} className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
                <a className={!licenseIsValid ? 'nolink' : ''}>{gettext.getString('журнал синхронизаций')}</a>
            </div>
        );
    };

    const openModal = (profile = 0) => {
        let modal = injector.instantiate(LdapProfileModal);
        modal.open(profile, profiles);
    };

    const handleRow = (id) => {
        openModal(id);
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        dispatch(deleteAccount(id));
    };

    return (
        <div id={'ldap-save-field'}>
            <SettingsHeader title={gettext.getString('Профили LDAP')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'} id={'ldap-field'}>
                <SettingsTable header={[
                    {name: gettext.getString('Название')},
                    {name: gettext.getString('URL')},
                    {name: gettext.getString('DN')},
                    {name: gettext.getString('Синхронизация')},
                    {name: ''},
                    {name: ''}
                ]}
                body={profiles}
                assets={{empty: gettext.getString('Нет профилей')}}
                cellSchema={
                    ldapSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid
                    })}
                ></SettingsTable>
            </div>
        </div>
    );
}

export default SettingsLdap;
