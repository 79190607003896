import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { GettextContext } from '../../context';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoursesSettings, fetchLearningConfig } from 'root/assets/webpack/src/js/toolkit/think';
import CoursesList from './CoursesList.jsx';
import ToolbarButtons from './ToolbarButtons.jsx';
import SnackBar from './Snackbar.jsx';
import { updateCoursesChecked, setCourses, returnCourse, addCourse } from '../../../toolkit/actions';
import load from '../../../../images/load-icon-course.svg';
import ProgressBar from '../ProgressBar';
import { CSSTransition } from 'react-transition-group';

function Courses({ }) {
    const gettext = useContext(GettextContext);
    const [checkboxReport, setCheckboxReport] = useState(false);
    const [checkboxAllCourses, setCheckboxAllCourses] = useState(false);
    const [popupDelete, setPopupDelete] = useState(false);
    const [popupDeleteDone, setPopupDeleteDone] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [courseName, setCourseName] = useState([]);
    const dispatch = useDispatch();
    const coursesList = useSelector((state) => state.courses.courses);
    const checkedCourses = coursesList.filter((item) => item.checked === true);
    const ids = checkedCourses.map((item) => item.id);
    const [snackbar, setSnackbar] = useState(false);
    const [sortClick, setSortClick] = useState({
        name: '',
        sort: false
    });
    const learning = useSelector((state) => state.learningConfig.config);
    const deleteCoursesList = useSelector(state => state.courses.deleteCoursesList);
    const [idsForDelete, setIdsForDelete] = useState(null);

    const sortList = [
        {
            id: 1,
            text: gettext.getString('Курс')
        },
        {
            id: 2,
            text: gettext.getString('Владелец')
        },
        {
            id: 3,
            text: gettext.getString('Роль')
        }
    ];

    const handleSort = (id) => {
        if (id === 1) {
            if (!sortClick.sort) {
                setSortClick({
                    id,
                    sort: true
                });
                dispatch(setCourses([...coursesList].sort((a, b) => (a.name >= b.name ? 1 : -1))));
            } else {
                setSortClick({
                    id,
                    sort: false
                });
                dispatch(setCourses([...coursesList].sort((a, b) => (a.name > b.name ? -1 : 1))));
            }
        }

        if (id === 2) {
            if (!sortClick.sort) {
                setSortClick({
                    id,
                    sort: true
                });
                dispatch(setCourses([...coursesList].sort((a, b) => (a.user.name >= b.user.name ? 1 : -1))));
            } else {
                setSortClick({
                    id,
                    sort: false
                });
                dispatch(setCourses([...coursesList].sort((a, b) => (a.user.name > b.user.name ? -1 : 1))));
            }
        }

        if (id === 3) {
            if (!sortClick.sort) {
                setSortClick({
                    id,
                    sort: true
                });
                dispatch(setCourses([...coursesList].sort((a, b) => (a.user.roles[0].name >= b.user.roles[0].name ? 1 : -1))));
            } else {
                setSortClick({
                    id,
                    sort: false
                });
                dispatch(setCourses([...coursesList].sort((a, b) => (a.user.roles[0].name > b.user.roles[0].name ? -1 : 1))));
            }
        }
    };

    useEffect(() => {
        dispatch(fetchLearningConfig());
        dispatch(fetchCoursesSettings());
    }, []);

    useEffect(() => {
        if (checkedCourses.length !== 0) {
            setSnackbar(true);
        } else {
            setSnackbar(false);
        }
    }, [checkedCourses]);

    const handlAllChecked = () => {
        setCheckboxAllCourses((prev) => !prev);
    };

    useEffect(() => {
        if (checkboxAllCourses) {
            coursesList.forEach(item => {
                if (item.checked === false) {
                    dispatch(updateCoursesChecked(item.id));
                }
            });
        } else {
            coursesList.forEach(item => {
                if (item.checked === true) {
                    dispatch(updateCoursesChecked(item.id));
                }
            });
        }
    }, [checkboxAllCourses]);

    const handleReturnCourse = () => {
        setStartTimer(false);
        setPopupDelete(false);
        setCourseName('');
        dispatch(returnCourse(deleteCoursesList));
    };

    const closeDeletePopup = () => {
        setStartTimer(false);
        setPopupDelete(false);
        setPopupDeleteDone(false);
        setCourseName('');
    };

    useEffect(() => {
        if (popupDeleteDone) {
            setTimeout(closeDeletePopup, 3000);
        }
    }, [popupDeleteDone]);

    const getCoursesList = () => {
        return <CoursesList coursesList={coursesList} />;
    };

    return (
        <div className={'react-courses'}>
            <div className={'react-courses_top'}>
                <div className={'settings-header'}>
                    <span className={'settings-header_title'}>{gettext.getString('Курсы')}</span>
                </div>
                <div className={`react-courses_top-checkbox ${learning.type !== 2 && 'react-final_disabled'}`}>
                    <input
                        checked={checkboxReport}
                        onChange={() => setCheckboxReport((prev) => !prev)}
                        className='react-checkbox-courses'
                        type='checkbox'
                    />
                    <label className='react-courses_label-checkbox'>
                        {gettext.getString('Включать в отчеты удаленные курсы')}
                    </label>
                </div>
                <ToolbarButtons
                    ids={ids}
                    checkedCourses={checkedCourses}
                    checkboxReport={checkboxReport}
                    startTimer={startTimer}
                    setCourseName={setCourseName}
                    setStartTimer={setStartTimer}
                    setPopupDelete={setPopupDelete}
                    setPopupDeleteDone={setPopupDeleteDone}
                    popupDelete={popupDelete}
                    learning={learning}
                    idsForDelete={idsForDelete}
                    setIdsForDelete={setIdsForDelete}
                />
            </div>
            <table className={'react-courses_table'}>
                <thead>
                    <tr className={'react-courses_header-wrapper'}>
                        <th></th>
                        <th>
                            <input
                                checked={checkboxAllCourses}
                                onChange={handlAllChecked}
                                className='react-checkbox-courses-all'
                                type='checkbox'
                            />
                            <label className='react-checkbox-courses_label-all'></label>
                        </th>
                        {sortList.map(({id, text}) => (
                            <th key={id} onClick={() => handleSort(id, text)} className={'react-courses-title'}>
                                <div className={'react-courses-title_sort'}>
                                    {text}
                                    {(sortClick.id === id && sortClick.sort) ?
                                        <svg className={'react-courses-title_svg-rotate'}>
                                            <use xlinkHref={'/image/icoSprite.svg#sort-icon'}></use>
                                        </svg>
                                        :
                                        (sortClick.id === id && !sortClick.sort) ?
                                            <svg className={'react-courses-title_svg'}>
                                                <use xlinkHref={'/image/icoSprite.svg#sort-icon'}></use>
                                            </svg>
                                            : ''
                                    }
                                </div>
                            </th>
                        ))}
                        <th className={'react-courses-title'}>{gettext.getString('Статус')}</th>
                    </tr>
                </thead>
                <tbody>{getCoursesList()}</tbody>
            </table>
            {popupDelete &&
                <div className={'react-courses_block-delete'}>
                    <div className={'react-courses_delete-popup'}>
                        <div className={'react-courses_delete-popup_content'}>
                            {
                                popupDeleteDone ?
                                    <div>
                                        <svg className='react-courses_delete-popup_delete'> <use xlinkHref={'/image/icoSprite.svg#popup-delete-icon-done'}></use> </svg>
                                    </div>
                                    :
                                    <div style={{ position: 'relative' }}>
                                        <div className='react-courses_progress-bar'>
                                            <ProgressBar />
                                        </div>
                                        <svg className='react-courses_delete-popup_delete'> <use xlinkHref={'/image/icoSprite.svg#popup-delete-icon'}></use> </svg>
                                    </div>
                            }
                            <span className={'react-courses_delete-popup_text'}>
                                <strong>{gettext.getString(`${courseName.length > 1 ? 'Вы удалили курсы' : 'Вы удалили курс'}`)}</strong>{' '}
                                <span>{gettext.getString(`${courseName.join(', ')}`)}</span>
                            </span>
                            {
                                !popupDeleteDone && <button data-testid='return-attack' onClick={handleReturnCourse} className={'react-courses_delete-popup_btn'}>{gettext.getString('Вернуть')}</button>
                            }
                        </div>
                    </div>
                </div>
            }
            <CSSTransition
                in={snackbar && !popupDelete && !popupDeleteDone}
                timeout={300}
                unmountOnExit
                classNames='scnackbar-transition'
            >
                <div className={'react-courses_block-snackbar'}>
                    <SnackBar
                        coursesList={coursesList}
                        setCheckboxAllCourses={setCheckboxAllCourses}
                        checkedCourses={checkedCourses}
                        learning={learning}
                    />
                </div>
            </CSSTransition>
        </div>
    );
}

export default Courses;
