import React, { useContext, useState } from 'react';
import { GettextContext } from 'rootReact/context';
import { useSelector } from 'react-redux';

function CourseItem ({courses}) {
    const gettext = useContext(GettextContext);
    const [listShow, setListShow] = useState(false);
    const [count] = useState(courses.length);
    const [noCourseError] = useState(gettext.getString('Курс не найден или больше не доступен'));
    const coursesList = useSelector((state) => state.courses.courses);

    const checkCourse = (item) => {
        let currentCourse = coursesList.filter(course => course.id === item);
        if (!currentCourse.length) {
            return {error: noCourseError};
        }
        return currentCourse[0];
    };

    return (
        <div className={'list_courses'}>
            {courses.map((item, i) => <span key={i} className={i > 2 && !listShow && !checkCourse(item).error ? 'hidden' : checkCourse(item).error ? 'text-danger' : ''}>{checkCourse(item).name ? checkCourse(item).name : checkCourse(item).error}</span>)}
            {count > 3 && <a className={'list_link'} onClick={() => setListShow(!listShow)}>
                <span className={`list_arrow ${listShow ? 'list_arrow__top' : 'list_arrow__bottom'}`}></span> {listShow
                    ? gettext.getString('Свернуть')
                    : gettext.getString('Показать все')}</a>}
        </div>
    );
}

export default CourseItem;
