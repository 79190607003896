import style from './style.modules.css';
import { hasAccess } from '../../reselect';
import { daemonsCancel } from '../../helpers/daemonsCancel';
import { fetchDaemonsMessage } from '../../toolkit/think';
import { defaultOneTimeMessage, daemonsMessageDefault } from '../../toolkit/actions';

class DaemonMessageController {
    constructor ($ngRedux, scope,  $timeout, gettextCatalog) {
        this.style = style;
        this.loading = true;
        this.timeout =  $timeout;
        this.scope = scope;
        this.updateMessage = null;
        this.titleText = [
            gettextCatalog.getString('Готовим архив сертификатов...'),
            gettextCatalog.getString('Готовим отчет по действиям...'),
            gettextCatalog.getString('Готовим отчет по обучению...'),
            gettextCatalog.getString('Готовим общий отчет...'),
            gettextCatalog.getString('Готовим отчет (полная статистика)...'),
            gettextCatalog.getString('Готовим отчет (анонимная статистика)...'),
            gettextCatalog.getString('Готовим отчет по уязвимостям...'),
            gettextCatalog.getString('Готовим отчет по атаке...'),
            gettextCatalog.getString('Готовим отчет по отделу...'),
            gettextCatalog.getString('Готовим отчет по обратной связи от сотрудников...'),
            gettextCatalog.getString('Готовим отчет по wifi-атаке...'),
        ];
        this.linkText = [
            gettextCatalog.getString('Скачать сертификаты сотрудников архивом'),
            gettextCatalog.getString('Скачать отчет по действиям'),
            gettextCatalog.getString('Скачать отчет по обучению'),
            gettextCatalog.getString('Скачать общий отчет'),
            gettextCatalog.getString('Скачать отчет (полная статистика)'),
            gettextCatalog.getString('Скачать отчет (анонимная статистика)'),
            gettextCatalog.getString('Скачать отчет по уязвимостям'),
            gettextCatalog.getString('Скачать отчет по атаке'),
            gettextCatalog.getString('Скачать отчет по отделу'),
            gettextCatalog.getString('Скачать отчет по обратной связи от сотрудников'),
            gettextCatalog.getString('Скачать отчет по wifi-атаке'),
        ];
        this.craftMessage = null;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {fetchDaemonsMessage, defaultOneTimeMessage, daemonsMessageDefault})(this);
        this.hidden = {};
    }

    mapStateToThis = (state) => {
        return {
            educationAccess: hasAccess(state, { sectionId: 1, rightName: 'target_education' }),
            daemonsMessage: state.daemonsMessage.daemons,
            message: state.daemonsMessage,
            userId: state.auth.auth.id,
            oneTimeMessage: state.oneTimeMessage.message
        };
    }

    $onInit () {
        const that = this;
        this.fetchDaemonsMessage();
        this.scope.$watch(scope => {
            return scope.$.message;
        }, message => {
            that.timeout.cancel(that.updateMessage);
            this.craftMessage = this.setMessageBody(message.daemons);
            if (that.checkNotifications()) {
                that.notificationsWatch(message.daemons);
                that.updateMessage = that.timeout(() => that.fetchDaemonsMessage(), 5000);
            }
        });
        this.scope.$watch(scope => {
            return scope.$.oneTimeMessage;
        }, message => {
            if (!message.length) return;
            that.craftMessage = that.setMessageBody(message);
            that.timeout(() => that.defaultOneTimeMessage(), 4000);
        });
    }

    setHidden = (id, initial = false) => {
        if (!id && !initial) {
            this.defaultOneTimeMessage();
        }
        this.hidden[id] = initial;
    }

    isDaemonNeedUpdate = () => {
        let needUpdate = false;
        if (!this.daemonsMessage.length) return false;
        this.daemonsMessage.forEach(item => {
            if (!item.isReady) {
                needUpdate = true;
            }
        });
        return needUpdate;
    }

    checkNotifications = () => {
        let hasNotifi = !!this.message.courses.length || !!this.message.notifications.length || this.isDaemonNeedUpdate();
        return hasNotifi;
    }

    notificationsWatch = (notification) => {
        notification.forEach(item => {
            if (!this.hidden.hasOwnProperty(item.id)) {
                this.hidden[item.id] = true;
            }
        });
    }

    cancelBuild = async (id) => {
        this.cancelLoading = true;
        let response = await daemonsCancel(id);
        if (response.status === 'success') {
            this.daemonsMessageDefault(id);
        }
        this.cancelLoading = false;
    }

    setMessageBody = (message) => {
        if (!message || !message.length) return null;
        let newMessage = {};
        message.forEach(item => {
            if (!this.hidden.hasOwnProperty(item.id)) {
                this.setHidden(item.id, true);
            }
            let messageIndex = item.id ? item.id : 0;
            newMessage[messageIndex] = {};
            newMessage[messageIndex].status = item.isReady;
            newMessage[messageIndex].progress = item.progress;
            newMessage[messageIndex].title = item.type ? this.titleText[item.type-1] : item.text;
            newMessage[messageIndex].link = item.type ? this.linkText[item.type-1] : '';
            newMessage[messageIndex].callback = () => item.id ? this.daemonsMessageDefault(item.type) : this.defaultOneTimeMessage;
        });
        return newMessage;
    }
}

DaemonMessageController.$inject = [
    '$ngRedux',
    '$scope',
    '$timeout',
    'gettextCatalog'
];

export {
    DaemonMessageController
};
