import { GettextContext } from 'rootReact/context';
import React, {useContext, useState} from 'react';
import { usePlural } from 'rootReact/hook';
import TriggerCell from './TriggerCell';
import ActionCell from 'rootReact/components/Scheduler/ActionCell';

export const schedulerSchema = ({ handleRow, iconHandle, disabled, disabledItem, triggerTypes, actionTypes}) => {
    const gettext = useContext(GettextContext);
    const setPluralTimeStart = (item) => {
        if (!item.delay_period || item.delay_period == 'minute') {
            return usePlural(item.delay_value, ['минута', 'минуты', 'минут']);
        } else if (item.delay_period == 'hour') {
            return usePlural(item.delay_value, ['час', 'часа', 'часов']);
        } else if (item.delay_period == 'day') {
            return usePlural(item.delay_value, ['день', 'дня', 'дней']);
        } else if (item.delay_period == 'week') {
            return usePlural(item.delay_value, ['неделя', 'недели', 'недель']);
        }
    };

    const setPluralTimeDelay = (task) => {
        if (!task.action_period || task.action_period === 'hour') {
            return usePlural(task.delay_value, ['раз в час', 'раза в час', 'раз в час']);
        } else if (task.action_period === 'day') {
            return usePlural(task.delay_value, ['раз в день', 'раза в день', 'раз в день']);
        } else if (task.action_period === 'week') {
            return usePlural(task.delay_value, ['раз в неделю', 'раза в неделю', 'раз в неделю']);
        } else if (task.action_period === 'month') {
            return usePlural(task.delay_value, ['раз в месяц', 'раза в месяц', 'раз в месяц']);
        }
    };

    const setStartName = (item) => {
        if (!item.delay_value && !item.action_value) {
            return `${gettext.getString('сразу')}`;
        }
        if (item.delay_value && !item.action_value) {
            return `${gettext.getString('с задержкой')} ${item.delay_value} ${setPluralTimeStart(item)}`;
        }
        if (item.action_value) {
            return `${gettext.getString('не чаще, чем')} ${item.action_value} ${setPluralTimeDelay(item)}`;
        }
    };

    return {
        row: {handleRow, disabled: () => false},
        cell: [
            {
                text: 'name',
            },
            {
                text: (trigger) => <TriggerCell trigger={trigger.triggers} triggerTypes={triggerTypes} />,
                class: 'scheduler_trigger'
            },
            {
                text: (action) => <ActionCell action={action} actionTypes={actionTypes}/>,
                class: 'scheduler_trigger'
            },
            {
                text: setStartName,
            }
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'pause delete',
                handle: [
                    ...iconHandle
                ]
            }
        ],
        changeIcon: (id) => {
            if (disabled) return 'noIcon';
            return disabledItem.indexOf(id) !== -1 ? 'play delete' : 'pause delete';
        }
    };
};
