import React, {useContext, useEffect} from 'react';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import {GettextContext} from '../../context';
import OutlookForm from 'rootReact/components/OutlookForm';
import {useSelector, useDispatch} from 'react-redux';
import OutlookManifest from 'rootReact/components/OutlookManifest/OutlookManifest.jsx';
import {fetchOutlookSettings} from '../../../toolkit/think';
import {setDefaultOutlook} from '../../../toolkit/actions';

function SettingsOutlook({manifestLink, licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const settings = useSelector(state => state.outlook.outlook);
    const firstLoading = useSelector(state => state.outlook.firstLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchOutlookSettings());
        return ()=>{dispatch(setDefaultOutlook());};
    }, []);

    return (
        <>
            {firstLoading && <>
                <div className={'settings_path'}>
                    <SettingsHeader title={gettext.getString('Плагин для почтовых клиентов')}/>
                    <OutlookForm
                        settings={settings}
                        licenseIsValid={licenseIsValid}
                    />
                </div>
                <div className={'settings_path settings_path__right'}>
                    <SettingsHeader title={''}/>
                    <OutlookManifest
                        manifestLink={manifestLink}
                        licenseIsValid={licenseIsValid}
                    />
                </div>
            </>
            }
        </>
    );
}

export default SettingsOutlook;
