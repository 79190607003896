import React from 'react';
import { ReactTableCell } from './ReactTableCell.jsx';

export function ReactTableRow({tableCell, cellSchema, id}) {
    const getTableCell = () => {
        let returned = cellSchema.cell.map((item, index) => {
            let newItem = {};
            if (item.class) {
                newItem.class = item.class;
            }
            if (typeof item.text === 'function') {
                newItem.text = item.text(tableCell);
            } else {
                newItem.text = tableCell[item.text];
            }
            return <ReactTableCell Style={cellSchema.row.disabled(tableCell.id)}
                key={newItem.text + id + index}
                viewItem={newItem}
                rowId={tableCell.id}/>;
        });
        if (cellSchema.icon) {
            returned = [...returned, ...cellSchema.icon.map((item) => {
                let newItem = null;
                if (cellSchema.changeIcon) {
                    newItem = JSON.parse(JSON.stringify(item));
                    newItem.icon = cellSchema.changeIcon(tableCell.id);
                    newItem.handle = item.handle;
                }
                return <ReactTableCell key={item.icon + tableCell.id}
                    viewItem={newItem || item}
                    rowId={tableCell.id}/>;
            })];
        }
        return returned;
    };

    return (
        <tr className='react-table_row'
            onClick={() => cellSchema.row.handleRow(tableCell.id)}
        >
            {getTableCell()}
        </tr>
    );
}
