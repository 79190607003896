import React from 'react';
import { ReactTableRow } from './ReactTableRow.jsx';

export function ReactTableBody({assets, body, cellSchema}) {

    const getTableBody = () => {
        if (!body.length) {
            return <tr><td className={'react-table__empty'} colSpan={cellSchema.cell.length}>{assets.empty}</td></tr>;
        }
        return body.map(
            (item) => (
                <ReactTableRow
                    key={item.id}
                    tableCell={item}
                    cellSchema={cellSchema}
                    id={item.id}
                />
            )
        );
    };

    return (
        <tbody>
            {getTableBody()}
        </tbody>
    );
}
