import React, {useContext, useState} from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { RoleModal } from 'root/assets/webpack/src/js/components/roleModal';
import { deleteAccount } from 'root/assets/webpack/src/js/toolkit/accountUsers';
import {useSelector, useDispatch} from 'react-redux';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { roleSchema } from './SettingsRoleScema.jsx';
import FetchApi from '../../REST';
import {setNewRole, editRole} from '../../../toolkit/actions';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import ModalRole from 'rootReact/components/ModalRole';

function SettingsRole({licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const roles = useSelector(state => state.roleUsers.roles);
    const profilesLdap = useSelector(state => state.settings.settings.ldapProfiles);
    const [syncStatus, setSyncStatus] = useState('ready');
    const dispatch = useDispatch();

    const getHeaderLink = () => {
        return (
            <div>
                <a onClick={() => licenseIsValid && openModal()}
                    className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
            </div>
        );
    };

    const openModal = async (id = 0, profilesLdap) => {
        let modal = injector.instantiate(ReactModal);
        let roleModal = await modal.open(
            {
                component: ModalRole,
                props: {
                    id,
                    profilesLdap
                },
            },
            'md'
        );
        //let response = await modal.open({roleId: id, ldapProfilesList: profilesLdap})
        if (!response) return;
        let role = await response.json();
        if (!id) {
            dispatch(setNewRole(role));
        } else {
            dispatch(editRole(role));
        }

    };

    const handleRow = (id) => {
        openModal(id);
    };

    const syncLdap = async (e, id) => {
        e.stopPropagation();
        setSyncStatus('inProcess');
        const fetchApi = FetchApi('/roles/sync');
        let response = await fetchApi.post({body: {id}});
        if (response.error) {
            setSyncStatus('error');
            return;
        }
        setSyncStatus('ready');
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        dispatch(deleteAccount(id));
    };

    return (
        <div style={{marginLeft: '15px', marginRight: '15px'}}>
            <SettingsHeader title={gettext.getString('Роли администраторов')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'}>
                <SettingsTable header={[
                    {name: gettext.getString('Название')},
                    {name: gettext.getString('Профили LDAP')},
                    {name: gettext.getString('Группы пользователей')},
                    {name: gettext.getString('Синхронизация')},
                    {name: ''},
                    {name: ''}
                ]}
                body={roles}
                assets={{empty: gettext.getString('Нет ролей')}}
                cellSchema={
                    roleSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid,
                        ldapProfiles: profilesLdap,
                        syncFunction: syncLdap,
                        syncStatus
                    })}
                ></SettingsTable>
            </div>
        </div>
    );
}

export default SettingsRole;
