import React from 'react';
import { getUserName, getCompanyName, isSSO } from '../../../reselect';
import { useSelector, useDispatch } from 'react-redux';
import HeaderLogo from 'rootReact/components/HeaderLogo';
import HeaderLink from 'rootReact/components/HeaderLink';
import { setLinkings } from 'root/assets/webpack/src/js/toolkit/actions';
import fetchApi from 'rootReact/REST';

function HeaderPage(props) {
    const {logoMode, logoModeHeight, logoModeTop, location} = props;
    const dispatch = useDispatch();
    const userName = useSelector(getUserName);
    const companyName = useSelector(getCompanyName);
    const authSSO = useSelector(isSSO);

    const go = (path, hash = 'top') => {
        location.path(path);
        dispatch(setLinkings(hash));
        if (hash === 'top') {
            location.hash('top');
        }
    };

    const logout = async () => {
        let url = authSSO ? '/sso/logout' : '/logout';
        let logout = fetchApi(url, true);
        let res = await logout.get();
        if (res.ssoRedirectUrl) {
            window.location.href = res.ssoRedirectUrl;
        } else {
            localStorage['ngStorage-userInfo'] = null;
            location.path('login');
        }
    };

    return (
        <div className={'react-wrapper header-page'}>
            <div className={'row'}>
                <HeaderLogo
                    logoMode={logoMode}
                    companyName={companyName}
                    logoModeHeight={logoModeHeight}
                    go={go}
                ></HeaderLogo>
                <HeaderLink
                    userName={userName}
                    go={go}
                    logout={logout}
                ></HeaderLink>
            </div>
        </div>
    );
}

export default HeaderPage;
