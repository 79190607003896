import dashboardSceletonItemTemplate from './view.html';
import {DashboardSceletonItemController} from './controller';

const DashboardSceletonItemComponent = {
    template: dashboardSceletonItemTemplate,
    controller: DashboardSceletonItemController,
    controllerAs: '$',
    bindings: {
        itemTitle: '<',
        chartId: '<',
        accessDashboardView: '<',
    },
};

export {
    DashboardSceletonItemComponent
};
