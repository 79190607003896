import { GettextContext } from 'rootReact/context';
import {useContext} from 'react';
import {usePlural} from 'rootReact/hook';
import FaIcon from 'rootReact/components/FaIcon';

export const roleSchema = ({ handleRow, iconHandle, disabled, ldapProfiles, syncFunction, syncStatus}) => {
    const gettext = useContext(GettextContext);
    const endingHours = (hours = 0) => [
        gettext.getString('Каждый {{count}} час', { count: hours }),
        gettext.getString('Каждые {{count}} часа', { count: hours }),
        gettext.getString('Каждые {{count}} часов', { count: hours }),
    ];
    const endingDays = (days = 0) => [
        gettext.getString('Каждый {{count}} день', { count: days }),
        gettext.getString('Каждые {{count}} дня', { count: days }),
        gettext.getString('Каждые {{count}} дней', { count: days }),
    ];
    return {
        row: {handleRow, disabled: () => false},
        cell: [
            {
                text: 'name',
            },
            {
                text: (role) => {
                    let ids = role.ldapSyncRefs.map(item => item.profileId);
                    let title = ldapProfiles.reduce((reduce, item) => {
                        if (ids.indexOf(item.id) !== -1) {
                            return reduce ? reduce + ', ' + item.title + ' ' : reduce + item.title;
                        }
                        return reduce;
                    }, '');
                    return title;
                },
            },
            {
                text: (role) => {
                    let array = role.ldapSyncRefs.reduce((reduce, item) => {
                        return [...reduce, ...item.groupNames];
                    }, []);
                    return array.join(', ');
                },
            },
            {
                text: (role) => {
                    if (!role.syncParameters) return '';
                    if (role.syncParameters.mode === 2) {
                        return gettext.getString('Вручную');
                    }
                    let hour = role.syncParameters.synchronizeInterval / 60 / 60;
                    if (hours / 24 === (hours / 24 ^ 0)) {
                        return usePlural(hours / 24, endingDays(hour/24));
                    }
                    return usePlural(hours, endingHours(hour));
                },
            },
            {
                text: (role) => {
                    if (!role.syncParameters) return '';
                    if (role.syncParameters.mode === 2 && syncStatus === 'ready') {
                        return <a onClick={(e) => syncFunction(e, role.id)}>{gettext.getString('Запустить сейчас')}</a>;
                    }
                    if (role.syncParameters.mode === 2 && syncStatus === 'inProcess') {
                        return <FaIcon type={'pending'} text={gettext.getString('Синхронизация') + '...'}/>;
                    }
                    if (role.syncParameters.mode === 2 && syncStatus === 'error') {
                        return <span><span>{gettext.getString('Ошибка')}</span> <a onClick={(e) => syncFunction(e, role.id)}>{gettext.getString('Повторить')}</a></span>;
                    }
                },
            },
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'delete',
                handle: [
                    ...iconHandle
                ]
            }
        ]
    };
};

