import React from 'react';

export function ReactTableHead({header}) {
    const getTableHead = () => {
        return header.map(
            (item, index) => (
                <th className={'react-table_header-cell'}
                    key={index}
                    onClick={ ()=>item.handleClick() }
                >{ item.name }</th>)
        );
    };
    return (
        <thead className={'react-table_header'}>
            <tr>{getTableHead()}</tr>
        </thead>
    );
}
