import React, {useContext} from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import {CourseModal} from '../../../components/courseModal';
import SettingsTable from 'rootReact/components/SettingsTable';
import {useSelector, useDispatch} from 'react-redux';
import { coursesSchema } from './SettingsCoursesSchema.jsx';
import {deleteCourses} from '../../../toolkit/think';

function SettingsCourses({licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const courses = useSelector(state => state.courses.courses);
    const dispatch = useDispatch();

    const getHeaderLink = () => {
        return (
            <div><a onClick={() => licenseIsValid && openModal()} className={`${!licenseIsValid ? 'nolink' : ''} settings_path-right`}>{gettext.getString('добавить')}</a></div>
        );
    };

    const openModal = (id = 0) => {
        let courseModal = injector.instantiate(CourseModal);
        courseModal.open(id);
    };

    const handleRow = (id) => {
        openModal(id);
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        dispatch(deleteCourses(id));
    };

    return (
        <div>
            <SettingsHeader title={gettext.getString('Курсы')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'}>
                <SettingsTable header={[
                    {name: gettext.getString('Название курса')},
                    {name: ''}
                ]}
                body={courses}
                assets={{empty: gettext.getString('Нет курсов')}}
                cellSchema={
                    coursesSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid
                    })}
                ></SettingsTable>
            </div>
        </div>
    );
}

export default SettingsCourses;
