import React from 'react';
import ReactIcon from 'rootReact/components/ReactIcons';

function DefaultList(props) {
    const {notificationsList,
        toggleNotification,
        toDefault,
        editNotification,
        copyNotification,
        editAccess,
        playAccess
    } = props;
    return notificationsList.map(item => {
        return (
            <div className={'list-item_wrapper'} key={item.uuid}>
                <div className={'list_row'}>
                    <div className={`list_label ${item.status ? '' : 'stop_item'}`}>{item.name}</div>
                    <div className={'list_button-sector'}>
                        {playAccess && (item.status ?
                            <div className={'list_icon-wrapper'}>
                                <ReactIcon icoClass={'pause-new'}
                                    listIcon={true}
                                    handleClick={() => toggleNotification(item.uuid)}
                                />
                            </div> :
                            <div className={'list_icon-wrapper'}>
                                <ReactIcon icoClass={'play-new'}
                                    listIcon={true}
                                    handleClick={() => toggleNotification(item.uuid)}
                                />
                            </div>)
                        }
                        {!item.isDefault && editAccess &&
                            <div className={'list_icon-wrapper'}>
                                <ReactIcon icoClass={'default'}
                                    listIcon={true}
                                    handleClick={() => toDefault(item.uuid)}
                                />
                            </div>
                        }
                        <div className={'list_icon-wrapper'}>
                            <ReactIcon icoClass={'edit-new'}
                                listIcon={true}
                                handleClick={() => editNotification(item.uuid)}
                            />
                        </div>
                        {editAccess && <div className={'list_icon-wrapper'}>
                            <ReactIcon icoClass={'copy-new'}
                                listIcon={true}
                                handleClick={ () => copyNotification(item.uuid, null)}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        );
    });
}

export default DefaultList;
