import React from 'react';

function SettingsHeader ({title, children}) {
    return (
        <div className={'settings-header'}>
            <span className={'settings-header_title'}>{title}</span>
            {children}
        </div>
    );
}

export default SettingsHeader;
