import { useEffect, useState} from 'react';

export const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidateForm(value, validations);

    const onChange = (e) => {
        setValue(e);
    };
    const onBlur = (e) => {
        setDirty(true);
    };

    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid,
    };
};

const useValidateForm = (value, validations) => {
    const [addressError, setAddressError] = useState(false);
    const [domainError, setDomainError] = useState(false);
    const [isEmpty, setEmpty] = useState(true);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'addressError':
                    const re = /^[A-Za-z0-9.\-\_]*$/;
                    re.test(String(value).toLowerCase()) ? setAddressError(false) : setAddressError(true);
                    break;
                case 'domainError':
                    const re2 = /^((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                    re2.test(String(value).toLowerCase()) ? setDomainError(false) : setDomainError(true);
                    break;
            }
        }
    }, [value]);

    useEffect(() => {
        value ? setEmpty(false) : setEmpty(true);
    }, [isEmpty, value]);

    return {
        addressError,
        domainError,
        isEmpty,
    };
};
