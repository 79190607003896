import React from 'react';
import { GettextContext, ConfigContext } from 'rootReact/context';
import {useContext, useState, useEffect, useRef} from 'react';
import SettingsHeader from '../SettingsSectionHeader';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanyName, getDomains, hasAccess } from '../../../reselect';
import SettingLicenseDomain from './SettingLicenseDomain.jsx';
import SettingsLicenseTill from './SettingsLicenseTill.jsx';
import SettingsLicenseProgress from './SettingsLicenseProgress.jsx';
import ReactSaveFile from '../ReactSaveFile';
import {setTargetCount} from '../../../toolkit/think';
import {useAutoWidth} from 'rootReact/hook';

function SettingsLicense({debugArchiveEnabled, licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const inputReference = useRef(null);
    const dispatch = useDispatch();
    const config = useContext(ConfigContext);
    const companyName = useSelector(getCompanyName);
    const initDomains = useSelector(getDomains);
    const settingsDebug = useSelector((state) => hasAccess(state, {sectionId: 5, rightName: 'settings_debug'}));
    const targetCountStatus = useSelector(state => state.auth.targetCountStatus);
    const version = useSelector(state => state.auth.auth.version);
    const targetCount = useSelector(state => +state.auth.auth.targetCount);
    const employeeCount = useSelector(state => +state.auth.auth.license.employeeCount);
    const companyTargetCount = useSelector(state => +state.auth.auth.companyTargetCount);
    const [numCompanyTarget, setNumCompanyTarget] = useState(companyTargetCount);
    const [showInput, setShowInput] = useState(false);
    const widthNumElement = useAutoWidth(numCompanyTarget, {minChars: 1});
    const validTill = useSelector(state => state.auth.auth.license.validTill);
    const supportTill = useSelector(state => state.auth.auth.license.supportTill);
    const isInfinity = useSelector(state => state.auth.auth.license.isInfinity);

    const handleShowInput = (isShow) => {
        if (!licenseIsValid) return;
        setShowInput(isShow);
        if (!isShow) {
            dispatch(setTargetCount(numCompanyTarget));
        }
    };

    useEffect(() => {
        inputReference.current && inputReference.current.focus();
    }, [showInput]);

    return (
        <section>
            <SettingsHeader title={gettext.getString('Лицензия')} right/>
            <SettingLicenseDomain
                companyName={companyName}
                initDomains={initDomains}
                licenseIsValid={licenseIsValid}
            />
            <SettingsLicenseTill
                validTill={validTill}
                isInfinity={isInfinity}
                supportTill={supportTill}
                licenseIsValid={licenseIsValid}
            />
            <div className={'settings_item'}>
                <span>{gettext.getString('покрывает {{count}} из', {count: employeeCount.toLocaleString()})}</span>
                {!showInput && targetCountStatus !== 'pending' ? <a onClick={()=>handleShowInput(true)}
                    className={`total-target-input ${!licenseIsValid ? 'nolink' : ''}`}>{companyTargetCount.toLocaleString()}</a> :
                    <input onBlur={() => handleShowInput(false)}
                        ref={inputReference}
                        onChange={(e) => {setNumCompanyTarget(e.target.value);}}
                        style={{width:widthNumElement}}
                        disabled={!licenseIsValid}
                        type='number'
                        className={'react-input_field react-input_field__focused'}
                        value={numCompanyTarget}/>}
                <span>{gettext.getString('сотрудников организации')}</span>
            </div>
            <div className={'settings_item'}>
                <SettingsLicenseProgress
                    companyTargetCount={companyTargetCount}
                    companyTargets={employeeCount}
                    targetInSystem={targetCount}
                    licenseIsValid={licenseIsValid}
                />
            </div>
            <span className={'settings_item'}>{targetCount.toLocaleString()} {gettext.getString('сотрудников добавлено в систему')}</span>
            {config.CUSTOM_STYLE !==1 ? (<div className={'settings_item settings_item__license-change'}>
                <span>{gettext.getString('для изменения условий лицензии\r\nотправьте запрос на адрес')}</span>
                <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a>
            </div>): ''}
            {version ? (<span className={'settings_item'}>{gettext.getString('Версия')} {version}</span>) : ''}
            {settingsDebug && debugArchiveEnabled ? (
                <ReactSaveFile
                    text={gettext.getString('Сохранить отладочную информацию')}
                    url={'/v2/settings/debug-archive'}
                    disabled={!licenseIsValid}
                    fileType={'zip'}
                    fileName={'debug'}
                />
            ) : ''}
        </section>
    );
}

export default SettingsLicense;
