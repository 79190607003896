import React, {useState } from 'react';

function UrlInput({value, setValue, Style, handleBlur, handleFocus}) {
    const [protocol, setProtocol] = useState(value && value.split('://')[0] || '');
    const [address, setAddress] = useState(value && value.split('://')[1] || '');

    const setParentValue = (value) => {
        setAddress(value);
        setValue(protocol + '://' + value);
    };

    const setParentProtocol = (value) => {
        setProtocol(value);
        setValue(value + '://' + address);
    };

    return (
        <div className={'react-button'}>
            <button className={`${protocol === 'http' ? 'react-button_element__active' : ''} react-button_element__protocol react-button_element react-button_element__left`}
                onClick={() => setParentProtocol('http')}
            >http://</button>
            <button className={`${protocol === 'https' ? 'react-button_element__active' : ''} react-button_element__protocol react-button_element react-button_element__center`}
                onClick={() => setParentProtocol('https')}
            >https://</button>
            <input className={Style}
                type={'text'}
                value={address}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={(e) => {setParentValue(e.target.value);}}
            />
        </div>
    );
}

export default UrlInput;
