import React from 'react';

function Input(props) {
    const {label, inputId, inputAttr, error, Style, control, prompt, center, textarea, PlaceHolder, type, disabled, labelStyle} = props;

    const getButton = () => {
        return (<button {...control.handle}>{control.value}</button>);
    };

    const getInput = () => {};

    const getControl = () => {
        if (!control) return null;
        if (control.type === 'button') {
            return getButton();
        } else {
            return getInput();
        }
    };

    return (
        <div className={`react-input ${center ? 'settings_item__center' : ''}`}>
            <label className={error ? `react-input_label__error react-input_label ${labelStyle}` : `${labelStyle} react-input_label`} htmlFor={inputId}>{label}</label>
            <div className={'react-input_wrapper'} style={prompt ? {flexWrap: 'wrap'} : null}>
                <div style={ {flexGrow: 1, margin: 0, display: 'flex'}}>
                    {!textarea ? <input
                        className={Style}
                        placeholder={PlaceHolder || ''}
                        name={label}
                        disabled={disabled}
                        type={type || 'text'}
                        id={inputId}
                        data-testid={inputId}
                        {...inputAttr}
                    /> : <textarea
                        className={Style}
                        name={label}
                        type={'text'}
                        id={inputId}
                        disabled={disabled}
                        rows={2}
                        data-testid={inputId}
                        {...inputAttr}
                        style={{height: textarea}}
                    />}
                    {getControl()}
                </div>
                {prompt && <span className={'react-prompt'}>{prompt}</span>}
            </div>
        </div>
    );
}

export default Input;
