import { createSlice } from '@reduxjs/toolkit';
import { deleteNotification } from 'root/assets/webpack/src/js/toolkit/notificationsList';

const initialState = {
    message: []
};

const oneTimeMessageSlice = createSlice({
    name: 'oneTimeMessage',
    initialState,
    reducers: {
        setOneTimeMessage (state, {payload}) {
            state.message.push(payload);
        },
        defaultOneTimeMessage (state) {
            state.shift();
        }
    },
    extraReducers: {
        [deleteNotification.rejected]: (state, { payload }) => {
            state.message.push(
                {
                    text: payload,
                    isReady: 'danger',
                    progress: 100
                });
        },
    }
});

export const {setOneTimeMessage, defaultOneTimeMessage} = oneTimeMessageSlice.actions;
export default oneTimeMessageSlice.reducer;
