import React from 'react';

function HeaderLogo(props) {
    const {companyName, logoModeHeight, go, logoMode} = props;
    const imageStyle = {
        maxHeight: +logoModeHeight + 'px'
    };
    return (
        <div className={'header-logo'}>
            <div className={'header-logo_image'} onClick={() => go('targets')}>
                <img style={imageStyle} src='/logo.svg'/>
            </div>
            {!logoMode && (<><div className={'header-logo_company-name'} onClick={() => go('targets')}>
                {window.config.PROJECT_NAME}
            </div>
            <div className={'header-logo_separator'}> / </div>
            <div className={'header-logo_description'}>
                {companyName}
            </div></>)}
        </div>
    );
}

export default HeaderLogo;
