import React from 'react';


function LanguageSelector({languages, onSelect, activeId}) {

    return (
        <div className={'modal-course_languages-selector'}>
            {languages.map(item => (
                <div key={item.id}
                    className={`modal-course_languages-selector_item ${activeId === item.id ? 'modal-course_languages-selector_item-active' : ''}`}
                    onClick={() => onSelect(item.id)}
                >{item.name}</div>))}


        </div>
    );
}


export default LanguageSelector;