const generalRules = {
    'card-name': {
        required: true,
    },
    'card-header': {
        required: true,
        maxLength: 100,
    },
    'card-text': {
        required: true,
        maxLength: 150
    },
    'card-buttonText': {
        required: true,
        maxLength: 30
    },
};

let customRules = {
    'card-from': {
        required: false,
    },
    'card-to': {
        required: false,
    },
    'card-subject': {
        required: false,
    },
    'card-copy': {
        required: false,
    },
    'card-token': {
        required: false,
    }
};

customRules = Object.assign(customRules, generalRules);

export {generalRules, customRules};
