import React, {useContext} from 'react';
import {GettextContext} from 'rootReact/context';
import FetchApi from 'rootReact/REST/fetchApi';
import {useSelector} from 'react-redux';

function PluginR7Setting({licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const pluginR7Link = useSelector(state => state.settings.settings.pluginR7Link);
    const auth = useSelector(state => state.auth.auth);

    const downloadPlugin = () => {
        if (!licenseIsValid) return;
        const fetchApi = FetchApi('', true);
        fetchApi.download(pluginR7Link, auth.email +'.xpi');
    };

    // @todo потом добавить ссылку на инструкцию
    const downloadInstruction = () => {
        if (!licenseIsValid) return;
        // const fetchApi = FetchApi(`/document/outlook_${langCode}.pdf`, true)
        // fetchApi.download(`/document/outlook_${langCode}.pdf`, instructionFileName[langCode])
    }

    return (
        <>
            <span className={'outlook_subtext'}>
                {gettext.getString('Скачайте')} <a className={!licenseIsValid ? 'nolink' : ''} onClick={downloadPlugin}>{gettext.getString('файл для установки плагина')} </a>
                        {gettext.getString('на Р7-Офис Органайзер')}
            </span>
            {/*<div className={'outlook_instructions-wrapper'}>*/}
                {/*<a className={!licenseIsValid ? 'nolink' : ''} onClick={downloadInstruction}>*/}
                {/*    {gettext.getString('Инструкция по установке для Р7')}*/}
                {/*</a>*/}
            {/*</div>*/}
        </>
    );
}

export default PluginR7Setting;
