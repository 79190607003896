export const variablesSchema = ({ handleRow }) => {
    return {
        row: {handleRow, disabled: () => false},
        cell: [
            {
                text: 'tag',
            },
            {
                text: 'name',
            },
        ]
    };
};

