import React, { useContext, useState } from 'react';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import { GettextContext } from 'rootReact/context';
import OutlookCard from 'rootReact/components/OutlookCard';
import {useSelector, useDispatch} from 'react-redux';
import newCardCreate from './newCardCreate.jsx';
import { setNewCard, deleteCard, setCardParams, setActive } from 'root/assets/webpack/src/js/toolkit/actions';
import FetchApi from '../../REST';
import OutlookPreview from 'rootReact/components/OutlookPreview';
import ConfirmPopup from 'rootReact/components/ConfirmPopup';

function OutlookCustomForm({pluginAttackSave}) {
    const gettext = useContext(GettextContext);
    const cards = useSelector((state) => state.outlook.outlook.card);
    const cardLoad = useSelector(state => state.outlook.cardLoad);
    const active = useSelector(state => state.outlook.activeCard);
    const cardSaved = useSelector(state => state.outlook.savedCard);
    const [deletedCard, setDeletedCard] = useState(null);
    const [timeOutRemove, setTimeOutRemove] = useState(null);
    const dispatch = useDispatch();

    const getCardsLink = () => {
        return cards.map( item => (
            <span key={item.settingId}
                className={`outlook-form_header-item ${item.settingId === active ? 'outlook-form_header-item__active' : ''}`}
            ><span className={item.type === 3 ? 'mr-1' : ''}
                    onClick={() => {
                        if (cardLoad) return;
                        return dispatch(setActive(item.settingId));
                    }}
                >{item.name}</span>{item.type === 3 && pluginAttackSave ? <span onClick={() => setRemove(item)} className={'react-cross'} /> : ''}</span>)
        );
    };

    const removeCard = async (card) => {
        if (!pluginAttackSave) return;
        if (cardLoad) return;
        if (card.settingId > 0) {
            await FetchApi('/outlook/card-settings').delete(card.settingId);
        }
        if (active === card.settingId) {
            dispatch(setActive(cards[0].settingId));
        }
        dispatch(deleteCard(card.settingId));
        setTimeout(() => setDeletedCard(null), 1000);
    };

    const getCard = () => {
        const activeCard = cards.filter(item => item.settingId === active)[0];
        return <OutlookCard
            key={active}
            {...activeCard}
            cardLoad={cardLoad}
            setCardParams={setCardParams}
            cardSaved={cardSaved}
            pluginAttackSave={pluginAttackSave}
        />;
    };

    const getPreview = () => {
        const activeCard = cards.filter(item => item.settingId === active)[0];
        return <OutlookPreview key={~Math.random()} {...activeCard} />;
    };

    const createNewCard = async () => {
        if (!pluginAttackSave) return;
        let newCard = await newCardCreate(gettext);
        newCard.header = gettext.getString('Похоже, вы смогли выявить реальную атаку!');
        dispatch(setNewCard(newCard));
        dispatch(setActive(newCard.settingId));
    };
    const setRemove =  (card) => {
        setDeletedCard(card.name);
        setTimeOutRemove(setTimeout( () => {
            removeCard(card);
        }, 8000));
    };

    const cancelRemove = () => {
        window.clearTimeout(timeOutRemove);
        setDeletedCard(null);
    };

    return (
        <div className={'outlook-form'}>
            {deletedCard && <ConfirmPopup setCancel={cancelRemove} name={deletedCard}/>}
            <div className={'outlook_mr15'}>
                <SettingsHeader title={gettext.getString('Настройка типов писем')} right />
            </div>
            <div className={'outlook-form_header'}>
                {getCardsLink()}
                {pluginAttackSave && <span className={'outlook-form_header-button'}
                    onClick={createNewCard}
                >{gettext.getString('Новый тип письма')}</span>}
            </div>
            <div className={'outlook-form_main'}>
                {getCard()}
                {getPreview()}
            </div>
        </div>
    );
}

export default OutlookCustomForm;
