import React, {useContext, useEffect, useState} from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { SchedulerTaskModal } from 'root/assets/webpack/src/js/components/schedulerTaskModal';
import { deleteAccount } from 'root/assets/webpack/src/js/toolkit/accountUsers';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { schedulerSchema } from './SchedulerSchema.jsx';
import { useSelector, useDispatch } from 'react-redux';

function Scheduler({licenseIsValid}) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const [disabledItem, setDisabled] = useState([]);
    const schedulerTasks = useSelector(state => state.settings.settings.schedulerTasks);
    const triggerTypes = useSelector(state => state.settings.settings.triggerTypes);
    const actionTypes = useSelector(state => state.settings.settings.actionTypes);
    const dispatch = useDispatch();

    useEffect(() => {
        let disabled = schedulerTasks.filter(item => !item.task_status).map(item => item.id);
        setDisabled(disabled);
    }, [schedulerTasks]);

    const getHeaderLink = () => {
        return (
            <div>
                <a onClick={() => licenseIsValid && openModal()} className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
                <a className={!licenseIsValid ? 'nolink' : ''}>{gettext.getString('журнал действий')}</a>
            </div>
        );
    };

    const openModal = (id = 0) => {
        let modal = injector.instantiate(SchedulerTaskModal);
        modal.open(id);
    };

    const handleRow = (id) => {
        openModal(id);
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        dispatch(deleteAccount(id));
    };

    return (
        <div id={'scheduler-field'}>
            <SettingsHeader title={gettext.getString('Планировщик')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'}>
                <SettingsTable header={[
                    {name: gettext.getString('Название')},
                    {name: gettext.getString('Что случилось')},
                    {name: gettext.getString('Что сделать')},
                    {name: gettext.getString('Выполнить')},
                    {name: ''}
                ]}
                body={schedulerTasks}
                assets={{empty: gettext.getString('Нет правил')}}
                cellSchema={
                    schedulerSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabledItem,
                        disabled: !licenseIsValid,
                        triggerTypes,
                        actionTypes,
                    })
                }
                ></SettingsTable>
            </div>
        </div>
    );
}

export default Scheduler;
