import { setAuthInfo, setAuthProps} from './auth';
import {setBreadcrumbLink, updateLinings} from './breadcrumbLinks';
import {setDashboardRound, setDashboardVersion} from './dashboard';
import {setLinkings} from './linking';
import {setOneTimeMessage, defaultOneTimeMessage} from './oneTimeMessage';
import {setSelectedData, clearSelectedData, setSearchData} from './selectedData';
import {setDefaultNotification, setNotification} from './notification';
import {setReportTypes, setTriggerTypes, setActionTypes} from './types';
import {setTemplates} from './templates';
import {setCourses, addCourse, updateCourse, updateCoursesChecked, returnCourse,  deleteCoursesFromList} from './courses';
import {daemonsMessageDefault} from './daemons';
import {attackHistoryDefault} from './attackHistory';
import {educationHistoryDefault} from './educationHistory';
import {setGraphMode} from './graphMode';
import {setChoosenLdapDepartment, setDefaultLdap} from './ldapDepartments';
import {setSSOLoading} from './loaderSso';
import {setMotivation} from './motivation';
import {targetHistoryDefault} from './targetHistory';
import {motivationHistoryDefault} from './motivationHistory';
import {setCampaignsRestoreData} from './campaigns';
import {applyFilterDepartment} from './departmentsFilter';
import {settingsDefault, setLearningConfig} from './settings';
import {setDefaultSystemNotification} from './systemNotification';
import {setAccountUsers, addAccount, updateAccount} from './accountUsers';
import {setRoles, editRole, setNewRole} from './roleUsers';
import { setListNotification, setOneNotification, updateOneNotification, updateDefault } from './notificationsList';
import {
    setLoadOutlook,
    setDefaultOutlook,
    setNewCard,
    deleteCard,
    setCardParams,
    setCardDownload,
    setActive,
    cancelSaved,
} from './outlookSettings';
import {setDashboardState} from './dashboardState';
import { addSsoProfile, updateSsoProfile } from './ssoProfie';
import {setDepartments} from './departments';
import {setLdapProfiles} from './ldapProfiles';
import { setWifiList, setWifi, updateWifi, updateWifiChecked } from './wifi';
import { setListWifiDevice, setWifiDevice, updateWifiDevice } from "./wifiDevice";
import {setFinalPages} from './finalPages';
import {setPhishingPages} from './phishingPage';
import {setWifiTargets, setWifiTargetsOne} from './wifiTargets';
import { setLangCode } from './languages';


export {
    addSsoProfile,
    updateSsoProfile,
    setAuthProps,
    setAuthInfo,
    setBreadcrumbLink,
    setDashboardRound,
    setDashboardVersion,
    setLinkings,
    setOneTimeMessage,
    defaultOneTimeMessage,
    setSelectedData,
    clearSelectedData,
    setSearchData,
    setDefaultNotification,
    setNotification,
    setReportTypes,
    setActionTypes,
    setTriggerTypes,
    setTemplates,
    setCourses,
    addCourse,
    updateCourse,
    returnCourse,
    deleteCoursesFromList,
    updateCoursesChecked,
    daemonsMessageDefault,
    attackHistoryDefault,
    educationHistoryDefault,
    setGraphMode,
    setChoosenLdapDepartment,
    setDefaultLdap,
    setSSOLoading,
    setMotivation,
    targetHistoryDefault,
    motivationHistoryDefault,
    setCampaignsRestoreData,
    applyFilterDepartment,
    updateLinings,
    settingsDefault,
    setDefaultSystemNotification,
    setLearningConfig,
    setAccountUsers,
    addAccount,
    updateAccount,
    setRoles,
    setLoadOutlook,
    setDefaultOutlook,
    editRole,
    setNewRole,
    setDashboardState,
    setNewCard,
    deleteCard,
    setCardParams,
    setCardDownload,
    setActive,
    cancelSaved,
    setWifiList,
    setWifi,
    updateWifi,
    setListWifiDevice,
    setWifiDevice,
    updateWifiDevice,
    setFinalPages,
    setPhishingPages,
    setWifiTargets,
    setWifiTargetsOne,
    setListNotification,
    setOneNotification,
    updateOneNotification,
    updateDefault,
    setDepartments,
    setLdapProfiles,
    updateWifiChecked,
    setLangCode
};
