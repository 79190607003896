import React, {useContext, useEffect, useState} from 'react';
import { GettextContext } from 'rootReact/context';
import FetchApi from '../../REST';
import moment from 'moment';
import { usePlural } from 'rootReact/hook';
import { getCurrentLanguage } from 'rootReact/hook/getCurrentLanguage.jsx';

const ConfirmEducation = ({onModalClose, courses, selectedData, time}) => {
    const gettext = useContext(GettextContext);
    const [targets, setTargets] = useState([]);
    const [load, setLoad] = useState(false);
    const lang = getCurrentLanguage();

    useEffect(() => {
        const getTargets = async () => {
            let apiTarget = FetchApi('/targets/search?fullList=1');
            let response = await apiTarget.post({body: {...selectedData, search : []}});
            setTargets(response);
            setLoad(true);
        };
        getTargets();
    }, []);

    const getCourses = () => {
        return courses.map(item => <span key={item.id} className={'confirm-course'}>
            {item.name}
        </span>);
    };

    const getTargets = () => {
        return targets.map(item => <div key={item.id} className={'confirm-row'}>
            <span className={'confirm-cell'}>{item.fullName}</span>
            <span className={'confirm-cell'}>{item.email}</span>
            <span className={'confirm-cell'}>{item.department.name}</span>
            <span className={'confirm-cell'}>{item.position}</span>
        </div>);
    };

    return (
        <div className={''} data-testid={'confirm-education-modal'}>
            <div className={'react-modal-header_content confirm-header'}>
                <div className={'confirm-header_title'}>{gettext.getString('Проверьте, что все правильно')}</div>
                <button data-testid='close' onClick={() => load && onModalClose()} className='react-modal-header_btn'>
                        ×
                </button>
            </div>
            <div className={'react-modal-header_content confirm-body'}>
                <div className={'confirm-block'} data-testid={'confirm-courses'}>
                    <div className={'confirm-title'}>{gettext.getString('Назначить на курсы')}</div>
                    <div className={'confirm-course_wrapper'}>
                        {getCourses()}
                    </div>
                </div>
                <div className={'confirm-targets'} data-testid={'confirm-targets'}>
                    <div className={'confirm-title'}>{gettext.getString(usePlural(selectedData.selectedCount.targets, ['{{count}} сотрудник', '{{count}} сотрудника', '{{count}} сотрудников']), {count: selectedData.selectedCount.targets})}</div>
                    <div className={'confirm-table'}>
                        <div className={'confirm-row confirm-row_title'}>
                            <span className={'confirm-cell_title'}>{gettext.getString('ФИО')}</span>
                            <span className={'confirm-cell_title'}>{gettext.getString('Электронная почта')}</span>
                            <span className={'confirm-cell_title'}>{gettext.getString('Отдел')}</span>
                            <span className={'confirm-cell_title'}>{gettext.getString('Должность')}</span>
                        </div>
                    </div>
                    <div className={'confirm-table'}>
                        {load ? getTargets() : <div className={'loader confirm-loader'}></div>}
                    </div>
                </div>
                <div className={'confirm-block'} data-testid={'confirm-date'}>
                    <div className={'confirm-title'}>{gettext.getString('Нужно будет пройти')}</div>
                    <div>
                        {gettext.getString('с {{from}} по {{to}}', {from: moment(time.timeFrom).lang(lang).format('DD MMMM'), to: moment(time.timeTo).lang(lang).format('DD MMMM YYYY')})} {gettext.getString('года')}
                    </div>
                </div>
            </div>
            <div className={'react-modal-header_content confirm-footer'}>
                <div className={`btn btn-default btn-left confirm-button ${!load && 'confirm-button__disabled'}`}
                    data-testid={'change-button'}
                    onClick={() => load && onModalClose()}>
                    <svg className={`confirm-arrow ${!load && 'confirm-arrow__disabled'}`} width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path fillRule='evenodd' clipRule='evenodd' d='M7.04042 11.7071C7.43094 11.3166 7.43094 10.6834 7.04042 10.2929L3.74753 7H14.6666C15.2189 7 15.6666 6.55229 15.6666 6C15.6666 5.44772 15.2189 5 14.6666 5H3.74753L7.04042 1.70711C7.43094 1.31658 7.43094 0.683418 7.04042 0.292894C6.6499 -0.0976312 6.01673 -0.0976312 5.62621 0.292894L0.626206 5.29289C0.235682 5.68342 0.235682 6.31658 0.626206 6.70711L5.62621 11.7071C6.01673 12.0976 6.6499 12.0976 7.04042 11.7071Z'/>
                    </svg>{gettext.getString('Изменить')}</div>
                <div className={`btn btn-primary btn-left btn_confirm ${!load && 'btn_confirm__disabled'}`}
                    data-testid={'confirm-button'}
                    onClick={() => load && onModalClose(true)}>{gettext.getString('Все верно, назначить')}</div>
            </div>
        </div>
    );
};

export default ConfirmEducation;
