import React, {useContext} from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useSelector, useDispatch} from 'react-redux';
import { VariableModal } from 'root/assets/webpack/src/js/components/variableModal';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { variablesSchema } from './SettingsVariablesSchema.jsx';

function SettingsVariables({}) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const variables = useSelector(state => state.settings.settings.variables);

    const getHeaderLink = () => {
        return (
            <div>
                <span className={'settings_path-right'}>{gettext.getString('добавляет техническая поддержка')}</span>
            </div>
        );
    };

    const openModal = (id = 0) => {
        let modal = injector.instantiate(VariableModal);
        modal.open(id);
    };

    const handleRow = (id) => {
        openModal(id);
    };

    return (
        <div>
            <SettingsHeader title={gettext.getString('Переменные')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'}>
                <SettingsTable header={[
                    {name: gettext.getString('Тег')},
                    {name: gettext.getString('Название')},
                ]}
                body={variables}
                assets={{empty: gettext.getString('Нет переменных')}}
                cellSchema={
                    variablesSchema({
                        handleRow,
                    })}
                ></SettingsTable>
            </div>
        </div>
    );
}

export default SettingsVariables;
